<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-row class="px-1 pt-4 pb-0">
        <v-col cols="12" sm="6" md="5" class="d-flex align-center">
          <!-- <span class="text-h6 font-weight-bold mr-2 primary--text">
            Cuentas por Cobrar
          </span>   -->
          <consultar-rango-fecha @consultar="solicitarCXCS" />
        </v-col>
        <v-col cols="12" v-if="$vuetify.breakpoint.xsOnly">
          <search-expand
            v-model="search"
            :load="loading"
            placeholder="Buscar Cuenta"
          />
        </v-col>
        <v-col cols="12" sm="6" md="7" class="d-flex align-center flex-row"
          :class="{'justify-end': $vuetify.breakpoint.smAndUp, 'justify-center mb-3': $vuetify.breakpoint.xsOnly}"
        >
          <search-expand
            v-model="search"
            :load="loading"
            placeholder="Buscar Cuenta"
            :expand="!$vuetify.breakpoint.xsOnly"
            v-if="!$vuetify.breakpoint.xsOnly"
          />
          <v-btn
            color="icono"
            class="mx-2"
            outlined
            small
            @click="updateCXC"
            :disabled="loading"
          >
            <v-icon size="22" :left="!$vuetify.breakpoint.smAndDown">mdi-update</v-icon>
            <span v-if="!$vuetify.breakpoint.smAndDown">Actualizar</span>
          </v-btn>
          <div class="d-flex align-center ml-1">
            <v-btn-toggle
              v-model="moneda"
              dense
              color="primary"
              borderless
             >
                <v-btn small :min-width="53" class="px-2" value="bolivares">
                  <span class="font-weight-bold display blue-grey--text">
                    Bs.
                  </span>
                </v-btn>

                <v-btn small :min-width="53" class="px-2" value="dolares">
                  <span class="font-weight-bold display blue-grey--text">
                    $
                  </span>
                </v-btn>
              </v-btn-toggle>
            </div>
            <!-- <v-btn
              color="primary"
              class="mx-2"
              small
              @click="showFilters = !showFilters"
            >
              <v-icon size="22" :left="!$vuetify.breakpoint.smAndDown">mdi-sort-variant</v-icon>
             <span v-if="!$vuetify.breakpoint.smAndDown">Filtros</span>
            </v-btn> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-divider />
        </v-col>
      </v-row>
      <v-expand-transition>
        <div v-if="showFilters">
          <v-row class="px-5 py-3">
            <v-col cols="12" class="pb-0">
              <span class="caption text-uppercase d-block font-weight-bold">
                Tipos de Precios
              </span>
            </v-col>
            <v-col cols="6" sm="3" md="2">
              <v-checkbox
              v-model="selected_price"
                label="Lista Precio 1"
                color="icono"
                value="1"
                hide-details
                class="mt-0 pt-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" sm="3" md="2">
              <v-checkbox
              v-model="selected_price"
                label="Lista Precio 2"
                color="icono"
                value="2"
                hide-details
                class="mt-0 pt-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" sm="3" md="2">
              <v-checkbox
              v-model="selected_price"
                label="Lista Precio 3"
                color="icono"
                value="3"
                hide-details
                class="mt-0 pt-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" sm="3" md="2">
              <v-checkbox
              v-model="selected_price"
                label="Lista Precio 4"
                color="icono"
                value="4"
                hide-details
                class="mt-0 pt-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" sm="3" md="2">
              <v-checkbox
              v-model="selected_price"
                label="Lista Precio 5"
                color="icono"
                value="5"
                hide-details
                class="mt-0 pt-0"
              ></v-checkbox>
            </v-col>
            <!-- <v-col cols="12" class="pb-0">
              <span class="caption text-uppercase d-block font-weight-bold">
                Rango de Fechas {{rango_inicio}} {{rango_final}}
              </span>
            </v-col>
            <v-col cols="12"
              class="d-flex flex-row"
              :class="{'flex-row align-end': $vuetify.breakpoint.smAndUp, 'flex-column ': $vuetify.breakpoint.xsOnly}"
              >
              <div>
                <label-form text="Inicio" required/>
                <input-fecha
                  v-model="rango_inicio"
                  color="blue-grey lighten-5"
                  />
              </div>
              <span class="blue-grey--text font-weight-medium mx-4 mt-3 caption">-</span>
              <div>
                <label-form text="Final" required/>
                <input-fecha
                  v-model="rango_final"
                  color="deep-orange lighten-5"
                  :disabled="!rango_inicio"
                  :min="rango_inicio"
                />
              </div>
              <div>
                <v-btn
                  color="blue-grey lighten-5"
                  class="mx-2"
                  :class="{'mt-3': $vuetify.breakpoint.xsOnly}"
                  depressed
                  @click="resetDate"
                  :block="$vuetify.breakpoint.xsOnly"
                >
                  Limpiar
                </v-btn>
              </div>
            </v-col> -->
          </v-row>
        </div>
      </v-expand-transition>
    </v-col>
    <v-col cols="12" class="px-2 pt-2">
      <v-toolbar color="header-data" dense flat class="rounded-lg mb-3">
        <v-row>
          <v-col cols="1" class="d-flex flex-row align-center px-0" v-if="!$vuetify.breakpoint.mobile">
            <a href="" class="text-decoration-none" @click.prevent="activeSort('campo2')">
              <span class="display" :class="{'font-weight-bold': columnActived('campo2') }">Pedido</span>
              <template v-if="columnActived('campo2')">
                <v-fade-transition>
                  <v-icon size="20" class="ml-1" color="blue-grey" v-text="orderSort ? 'mdi-sort-variant' : 'mdi-sort-reverse-variant'"/>
                </v-fade-transition>
              </template>
              <v-icon v-else color="blue-grey lighten-3">mdi-menu-swap</v-icon>
            </a>
          </v-col>
          <v-col cols="6" md="4" lg="4" class="d-flex flex-row align-center">
            <span class="display" v-text="modo"></span>
          </v-col>
          <v-col cols="6" md="2" class="d-flex flex-row justify-center align-center">
           <a href="" class="text-decoration-none" @click.prevent="activeSort('saldo')">
              <span class="display" :class="{'font-weight-bold': columnActived('saldo')}">Saldo</span>
              <template v-if="columnActived('saldo')">
                  <v-icon size="20" class="ml-1" color="blue-grey" v-text="orderSort ? 'mdi-sort-variant' : 'mdi-sort-reverse-variant'"/>
              </template>
              <v-icon v-else color="blue-grey lighten-3">mdi-menu-swap</v-icon>
            </a>
          </v-col>
          <v-col cols="1" class="d-flex flex-row justify-center align-center px-0" v-if="!$vuetify.breakpoint.mobile">
            <a href="" class="text-decoration-none" @click.prevent="activeSort('TipoPrecio')">
              <span class="display" :class="{'font-weight-bold': columnActived('TipoPrecio')}">Precio Venta</span>
              <template v-if="columnActived('TipoPrecio')">
                  <v-icon size="20" class="ml-1" color="blue-grey" v-text="orderSort ? 'mdi-sort-variant' : 'mdi-sort-reverse-variant'"/>
              </template>
              <v-icon v-else color="blue-grey lighten-3">mdi-menu-swap</v-icon>
            </a>
          </v-col>
          <v-col cols="6" md="2" lg="2" class="d-flex flex-row align-center" v-if="!$vuetify.breakpoint.mobile">
            <a href="" class="text-decoration-none" @click.prevent="activeSort('fecha_recibido')">
              <span class="display" :class="{'font-weight-bold': columnActived('fecha_recibido')}">Recibido</span>
              <template v-if="columnActived('fecha_recibido')">
                  <v-icon size="20" class="ml-1" color="blue-grey" v-text="orderSort ? 'mdi-sort-variant' : 'mdi-sort-reverse-variant'"/>
              </template>
              <v-icon v-else color="blue-grey lighten-3">mdi-menu-swap</v-icon>
            </a>
          </v-col>
          <v-col cols="6" md="2" lg="2" class="d-flex flex-row px-0 align-center" v-if="!$vuetify.breakpoint.mobile">
            <a href="" class="text-decoration-none" @click.prevent="activeSort('dias_vencida')">
              <span class="display" :class="{'font-weight-bold': columnActived('dias_vencida')}">Días Vencida</span>
              <template v-if="columnActived('dias_vencida')">
                  <v-icon size="20" class="ml-1" color="blue-grey" v-text="orderSort ? 'mdi-sort-variant' : 'mdi-sort-reverse-variant'"/>
              </template>
              <v-icon v-else color="blue-grey lighten-3">mdi-menu-swap</v-icon>
            </a>
          </v-col>
        </v-row>
      </v-toolbar>
       <v-data-iterator
        :items="cuentasxcobrar"
        :items-per-page="10"
        :search="search"
        :loading="loading"
        :footer-props="{itemsPerPageText: 'Mostrar'}"
        :sort-by="sortBy"
        :sort-desc="orderSort"
      >
        <template v-slot:default="{ items }">
          <v-expansion-panels focusable hover>
            <template v-for="(cxc,i) in items">
              <item-cxc :type="modo" :cuenta="cxc" :currency="simbolo" :key="'cxc-'+i" />
            </template>
          </v-expansion-panels>
        </template>
        <template #loading>
          <v-skeleton-loader
            type="list-item-two-line, list-item, list-item-two-line"
          ></v-skeleton-loader>
        </template>
        <template v-slot:no-data>
          <div class="text-center pa-4">
            <span class="blue-grey--text font-italic">No se encontraron Cuentas por Cobrar</span>
          </div>
        </template>
      </v-data-iterator>

    </v-col>
  </v-row>
</template>
<script>
import moment from 'moment'
export default {
  name: 'DataCxc',
  props:{
     datacxc: {
      type: Object,
      default: () => ({}),
    },
    loading: Boolean,
    modo: {
      type: String,
      default: ''
    }
  },
  components:{
    ItemCxc: () => import(
      /* webpackChunkName: "modal-account" */
      './ItemCxc.vue'
    ),
    ConsultarRangoFecha: () => import(
      /* webpackChunkName: "consultar-rango-fecha" */
      '@/widgets/ConsultarRangoFecha.vue'
    ),
    // InputFecha: () => import(
    //   /* webpackChunkName: "input-fecha" */
    //   '@/views/Reportes/Components/InputFecha.vue'
    // ),
  },
  data: () =>({
    search: '',
    moneda: 'dolares',
    selected_price: [],
    rango_inicio: '',
    rango_final: '',
    showFilters: false,
    pageData: 1,
    sortBy: '',
    orderSort: true,
  }),
  methods:{
    updateCXC(){
      this.$emit('update');
    },
    resetDate () {
      this.rango_inicio = ''
      this.rango_final = ''
    },
    activeSort(column){
      this.sortBy = column;
      this.orderSort = !this.orderSort;
    },
    columnActived(column){
      return column === this.sortBy
    },
    solicitarCXCS(fechas){
      this.$emit('updateDataCXC', fechas);
    }
  },
  computed:{
    cuentasxcobrar () {
      return this.datacxc[`cxc_${this.moneda}`] ? this.datacxc[`cxc_${this.moneda}`].map(function(item){
        const recibido = new moment(item.fecha_recibido);
        const hoy = new moment();
        return {
          ...item,
          retraso: hoy.diff(recibido, 'day')
        }
      }).filter (item => {
        let [moneda, precio] = item.TipoPrecio != null ? item.TipoPrecio.toString().split('') : ['',''];
        return this.selected_price.length > 0 ? this.selected_price.includes(precio) : item
      }) : [];
      // .filter(item => {
      //   return this.rango_inicio != '' && this.rango_final != '' ? moment(item.fecha_recibido).isBetween(this.rango_inicio, this.rango_final, undefined, '[]') : item ;
      // })
    },
    simbolo(){
      return this.moneda === 'dolares' ? '$' : 'Bs.'
    }
  },
}
</script>
